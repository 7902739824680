<template>
  <div>
    <b-card-group deck>
      <b-card header="Tạo mới thông tin trường học" header-tag="header">
        <b-form v-if="show">
          <div class="row">
            <!-- Mã trường -->
            <div class="col-md-8">
              <b-form-group
                id="input-dk-2"
                label="Mã trường(*)"
                label-for="ms-title"
              >
                <b-form-input
                  id="ms-title"
                  v-model="form.code"
                  maxlength="20"
                  placeholder="Mã trường"
                  :class="[{ 'is-invalid': form.errors.has('code') }]"
                ></b-form-input>
                <has-error :form="form" field="title"></has-error>
              </b-form-group>
            </div>

            <!-- Tên trường -->
            <div class="col-md-8">
              <b-form-group
                id="input-dk-2"
                label="Tên trường(*)"
                label-for="ms-title"
              >
                <b-form-input
                  id="ms-title"
                  v-model="form.name"
                  maxlength="191"
                  placeholder="Tên trường"
                  :class="[{ 'is-invalid': form.errors.has('name') }]"
                ></b-form-input>
                <has-error :form="form" field="title"></has-error>
              </b-form-group>
            </div>

            <!-- Icon trường -->
            <div class="col-md-8">
              <b-form-group label="Icon:">
                <div>
                  <div style="height: 50px" v-if="form.icon">
                    <img
                      style="width: 38px; height: 38px"
                      :src="form.icon"
                      alt=""
                    />
                  </div>
                  <b-form-file
                    @change="onImageChange($event, 'icon')"
                    placeholder="chọn ảnh"
                    accept="image/*"
                    type="file"
                    :class="[{ 'is-invalid': form.errors.has('icon') }]"
                  ></b-form-file>
                  <has-error :form="form" field="icon"></has-error>
                </div>
              </b-form-group>
            </div>

            <!-- Domain trường -->
            <div class="col-md-8">
              <b-form-group
                id="input-dk-2"
                label="Domain trường(*)"
                label-for="ms-title"
              >
                <b-form-input
                  id="ms-title"
                  v-model="form.domain"
                  maxlength="191"
                  placeholder="Domain trường"
                  :class="[{ 'is-invalid': form.errors.has('domain') }]"
                  :disabled="is_disable"
                ></b-form-input>
                <has-error :form="form" field="title"></has-error>
              </b-form-group>
            </div>

            <!-- Trạng thái -->
            <div class="col-md-12">
              <b-form-group
                id="input-dk-2"
                label="Trạng thái"
                label-for="ms-title"
              >
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="form.status"
                  value="1"
                  unchecked-value="0"
                  size="lg"
                >
                  Kích hoạt
                </b-form-checkbox>
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="form.status"
                  value="0"
                  unchecked-value="0"
                  size="lg"
                >
                  Không kích hoạt
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>

          <hr />
          <div class="row">
            <div class="col-md-12" style="text-align: right">
              <b-button
                v-if="!is_edit"
                type="submit"
                variant="primary"
                style="margin-right: 10px"
                @click="saveForm"
              >
                Tạo mới
              </b-button>
              <b-button
                v-else
                type="submit"
                variant="primary"
                style="margin-right: 10px"
                @click="updateUniversity"
              >
                Cập nhật
              </b-button>
              <router-link v-if="is_edit" :to="'/campaign/university'">
                <b-button variant="danger">Hủy bỏ</b-button>
              </router-link>
              <b-button v-else type="reset" variant="danger">Làm mới</b-button>
            </div>
          </div>
        </b-form>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";
import Multiselect from "vue-multiselect";
import Vue from "vue";
import { Form } from "vform";
import { HasError } from "vform/src/components/bootstrap5";

Vue.component("multiselect", Multiselect);
Vue.component(HasError.name, HasError);
const CampaignRepository = RepositoryFactory.get("Campaign");
const WalletCampaignRepository = RepositoryFactory.get("comboVoucher");
const WalletRunRepository = RepositoryFactory.get("WalletRun");

export default {
  name: "FormUniversity",
  mixins: [Common],
  components: {},
  data() {
    return {
      form: new Form({
        id: "",
        code: "",
        name: "",
        icon: "",
        status: 1,
        domain: "",
        items: [],
      }),
      clients: [],
      events: [],
      show: true,
      is_edit: false,
      is_disable: false,
    };
  },
  computed: {
    clientID() {
      return this.form.client_id;
    },
  },
  watch: {
    clientID() {
      this.loadEvents();
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lí trường học ", route: "/campaign/university" },
      { title: "Thêm mới trường học" },
    ]);
  },
  methods: {
    saveForm() {
      this.form
        .submit("post", "/campaign/university")
        .then(({ data }) => {
          if (1 === data.error_code) {
            this.$notify({
              group: "foo",
              type: "error",
              title: data.message,
            });
          } else {
            this.$notify({
              group: "foo",
              type: "success",
              title: data.message,
            });
            this.$router.push({ name: "campaign_university" });
          }
        })
        .catch(() => {
          this.$notify({
            group: "foo",
            type: "error",
            title: "Vui lòng kiểm tra lại form nhập liệu.",
          });
        });
    },
    async getClients() {
      let response = await WalletCampaignRepository.getClients();
      this.clients = response.data.data;
    },
    async loadEvents() {
      try {
        this.$bus.$emit("show-loading", true);
        let filter = {
          client_id: this.form.client_id,
          is_show_all: true,
        };
        let response = await WalletCampaignRepository.getEvents(filter);
        this.$bus.$emit("show-loading", false);
        this.events = response.data.data;
      } catch (error) {
        this.$bus.$emit("show-loading", false);
      }
    },
    async loadUniversity(id) {
      try {
        let response = await CampaignRepository.getUniversityDetail(id);
        if (response.data.error_code === 0) {
          let data = response.data.data;
          this.form.fill(data);
          if(data.status == 2){
            this.is_disable = true;
          }
          if (data.length > 0) {
            let that = this;
            this.universityItems = [];
            this.form.items.forEach((item) => {
              let value = "";
              that.universityItems.push({
                id: item.id,
                type: item.type,
                value: value,
                num_receive: item.num_receive,
              });
            });
          }
        } else {
          this.$notify({
            group: "foo",
            type: "error",
            title: response.data.message,
          });
        }
      } catch (error) {
        this.$notify({
          group: "foo",
          type: "error",
          title: error,
        });
      }
    },
    updateUniversity() {
      this.form.items = this.giftItems;
      this.form
        .submit("post", "/campaign/university/" + this.form.id)
        .then(({ data }) => {
          if (1 === data.error_code) {
            this.$notify({
              group: "foo",
              type: "error",
              title: data.message,
            });
          } else {
            this.$notify({
              group: "foo",
              type: "success",
              title: data.message,
            });
            this.$router.push({ name: "campaign_university" });
          }
        })
        .catch((error) => {
          this.$notify({
            group: "foo",
            type: "error",
            title: error,
          });
        });
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        title: "",
        desc: "",
        desc_detail: "",
        type: "",
        status: 0,
        client_id: "",
        event_id: "",
        items: [],
        icon: "",
      };
      this.giftItems = [
        {
          type: 1,
          value: "",
          num_receive: "",
        },
      ];
      this.$nextTick(() => {
        this.show = true;
      });
    },
    onImageChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      let fileSize = files[0].size / 1024 / 1024; // in MiB
      if (fileSize > 2) {
        this.$notify({
          group: "foo",
          type: "error",
          title: "Chọn ảnh không vượt quá 2MB",
        });
        return false;
      }
      this.images = files[0];
      this.uploadMedia();
      //this.createImage(files[0]);
    },
    uploadMedia: async function () {
      this.$bus.$emit("show-loading", true);
      const formData = new FormData();
      formData.append("file", this.images);

      WalletRunRepository.uploadMedia(formData)
        .then((response) => {
          this.$bus.$emit("show-loading", false);
          if (1 === response.data.error_code) {
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          } else {
            this.form.icon = response.data.data.link;
          }
        })
        .catch((response) => {
          this.$bus.$emit("show-loading", false);
          this.$notify({
            group: "foo",
            type: "error",
            title: response.data.message,
          });
        });
    },
  },
  created() {
    this.getClients();
    if (this.$route.params.id) {
      this.is_edit = true;
      this.loadUniversity(this.$route.params.id);
    }
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.title-block {
  color: #ff0000;
  font-size: 18px;
  font-weight: bold;
}
.datetime-picker div input {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3f4254;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #e4e6ef;
  border-radius: 0.42rem;
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>
